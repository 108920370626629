import Header from "@/components/organisms/Header";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import useWindowSize from "@/hooks/useWindowSize";

type Props = {
  data: any;
};

const CarouselHeader = (props: Props) => {
  const windowSize = useWindowSize();
  const { data } = props;
  return (
    <div className="">
      <Carousel
        showIndicators={true}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        autoPlay={true}
        interval={5000}
        transitionTime={1000}
        infiniteLoop={windowSize.width < 675 ? true : false}
        className="carousel_custom carousel_header container overflow-hidden"
        swipeScrollTolerance={100}
        preventMovementUntilSwipeScrollTolerance
      >
        {data.carouselItemList.map((item: any, key: number) => {
          return (
            <div
              key={key}
              className="relative w-full md:max-h-[676px] aspect-square "
            >
              <Header
                index={key}
                lastIndex={data.carouselItemList.length - 1}
                key={key}
                data={item}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CarouselHeader;
